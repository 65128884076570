
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mdiPlus, mdiChevronRight } from '@mdi/js';

import { FeatureModule, UserModule } from '@/store/modules';
import { CardDetail, IAuthUser } from '@/types/user.types';
import { IBasePaged } from '@/types/base.types';

import CreditCard from '@/components/settings/CreditCard.vue';
import { MemberService } from '@/services';

@Component({
  components: {
    CreditCard
  }
})
export default class LinkedCards extends Vue {
  @Prop() card!: CardDetail;

  cards: IBasePaged<CardDetail> = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };

  icons = {
    mdiPlus: mdiPlus,
    mdiChevronRight: mdiChevronRight
  };

  isEmpty = false;
  isCardLimit = true;
  isRoundUpEnabled = FeatureModule.getIsRoundUpEnabled;
  setCardAsDefault = false;

  async setDefault(cardId: string) {
    const response = await MemberService.setCardAsDefault(cardId);
    window.location.reload();
    return response;
  }

  async getLinkedCards() {
    const response = await MemberService.getCards();
    this.cards = response;

    if (!(response.totalNumberOfRecords > 0)) {
      this.isEmpty = true;
    }
    if (response.totalNumberOfRecords && response.totalNumberOfRecords >= 2) {
      this.isCardLimit = true;
    } else {
      this.isCardLimit = false;
    }
  }

  created() {
    this.getLinkedCards();
  }

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get cardDetail() {
    return this.card || {};
  }
}
