
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiChevronRight } from '@mdi/js';

import { UserModule } from '@/store/modules';
import { CardDetail, IAuthUser } from '@/types/user.types';

@Component
export default class CreditCard extends Vue {
  @Prop() card!: CardDetail;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get cardDetail() {
    return this.card || {};
  }

  icons = {
    mdiChevronRight: mdiChevronRight
  };
}
